import React, { useEffect } from 'react';
import Navbar from './Navbar';
import image17 from '../images/aboutus.png';
import logo from '../images/logo.png';
import image16 from '../images/man.png';
import Homepage1 from './Homepage1';
import Footer from './Footer';
import man from '../images/m1.png';
import man1 from '../images/m2.png';
import man2 from '../images/m3.png';
import man3 from '../images/m4.png';
import man5 from '../images/m5.png';
import man6 from '../images/m6.png';
import man7 from '../images/m7.png';
import imagee from '../images/last.png';



const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

    return (
        <div>
            <Navbar />
            <br/><br/><br/><br/><br/><br/><br/>

            {/* Container for image12 and image13 */}
            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
  <img
    src={image17}
    alt="facilities"
    style={{ width: '100%', height: '500px', objectFit: 'cover' }}
  />
  <div style={{
    position: 'absolute',
    top: '50%',
    left: '5%', // Adjust as needed
    transform: 'translateY(-50%)',
    color: 'lightgray',
    fontFamily: 'sans-serif',
    fontSize: '54px',
    lineHeight: '1.5',
    padding: '10px'
  }}>
    <div>WE DON'T BUILD</div>
    <div>HOPES</div>
    <div>WE BUILD YOUR</div>
    <div>FUTURE</div>
  </div>
</div>

<br/>
<div style={{ display: 'flex', alignItems: 'flex-start', padding: '20px' }}>
  <img
    src={logo}
    alt="facilities"
    style={{ width: '300px', height: '150px', objectFit: 'cover' }}
  />
  <div style={{ flex: 1, marginLeft: '20px', textAlign: 'right' }}>
    <h2>ABOUT GROUNDS AND SPORTS</h2>
    <p style={{ textAlign: 'justify' }}>
      Founded in 2015 in Napier, New Zealand, and expanded to Bengaluru, India, Grounds & 
      Sports stands at the intersection of passion and professionalism in athletic development. 
      Specializing in basketball, badminton, cricket, and football, we are dedicated to shaping 
      the next generation of sports champions through expert coaching, state-of-the-art facilities,
       and a commitment to excellence. From grassroots initiatives to elite training camps, 
       we provide a supportive environment where athletes of all ages and skill levels can thrive.
        Join us as we continue to build a legacy of achievement and sportsmanship across local and
         international arenas.
    </p>
  </div>
</div>


<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh', marginTop: '20px' }}>
  <div style={{ 
    backgroundColor: 'black', // Light gray background color
    padding: '20px', // Padding inside the card
    borderRadius: '8px', // Rounded corners for the card
    display: 'flex', 
    alignItems: 'center', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional shadow for better appearance
    height: '250px', // Increased height of the card
    width: '1350px' // Increased width of the card
  }}>
    <img
      src={image16}
      alt="man"
      style={{ height: '250px', width: '250px', marginRight: '20px' }}
    />
    <div>
      <p style={{ fontFamily: 'Sans-serif', fontSize: '38px', fontWeight: 'bold', margin: 0, whiteSpace: 'nowrap' }}>
        <span style={{ color: 'orange' }}>Cricket is a mind game</span>,&nbsp;
        <span style={{ color: 'white' }}>more than a physical game</span>
      </p><br/><br/>
      <p style={{ fontFamily: 'Sans-serif', fontSize: '18px', fontWeight: 'bold', margin: '10px 0 0', textAlign: 'right', color: 'white' }}>
        -MATHEW SINCLAIR
      </p>
      <p style={{ fontFamily: 'Sans-serif', fontSize: '14px', margin: '0', textAlign: 'right', color: 'white' }}>
        Former International New Zealand Cricketer
      </p>
    </div>
  </div>
</div>

<br/><br/>

<div style={{ flex: 1, marginLeft: '20px', textAlign: 'center' }}>
    <h2>OUR AGENDA</h2>

    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px'
    }}>
        <div style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            width: '100%',
            maxWidth: '1250px',
            minHeight: '300px'
        }}>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '20px'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <i className="fas fa-hands-helping" style={{ fontSize: '20px' }}></i>
                    <p>
                        <strong>Support</strong><br/>
                        We prioritize a supportive environment for our athletes, offering guidance from certified international coaches.
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <i className="fas fa-chart-line" style={{ fontSize: '40px' }}></i>
                    <p>
                        <strong>Skills</strong><br/>
                        Through structured coaching and tailored training sessions, we aim to enhance the skills of every participant.
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <i className="fas fa-user-plus" style={{ fontSize: '40px' }}></i>
                    <p>
                        <strong>Onboarding</strong><br/>
                        Our dedicated team ensures seamless registration and provides comprehensive guidance to integrate newcomers into our dynamic sporting community.
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <i className="fas fa-dumbbell" style={{ fontSize: '40px' }}></i>
                    <p>
                        <strong>Training</strong><br/>
                        At Grounds And Sports, training is meticulously designed to elevate athletes' skills and confidence.
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <i className="fas fa-trophy" style={{ fontSize: '40px' }}></i>
                    <p>
                        <strong>Success</strong><br/>
                        At Grounds And Sports, success is defined by our athletes' consistent excellence in local and national competitions.
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <i className="fas fa-medal" style={{ fontSize: '40px' }}></i>
                    <p>
                        <strong>Result</strong><br/>
                        Our players consistently showcase their skills and dedication, often achieving top rankings and recognition.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<br/>
    
        <div style={{ 
            marginLeft: '60px', 
            padding: '20px', 
            backgroundImage: `url(${logo})`, 
            backgroundSize: 'contain', 
            backgroundRepeat: 'no-repeat', 
            backgroundPosition: 'center',
            position: 'relative'
        }}>
            {/* Overlay */}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.8)', // Lighten or darken the overlay as needed
                zIndex: 1
            }} />

            {/* Content */}
            <div style={{ position: 'relative', zIndex: 2 }}>
                <h2 style={{ color: 'orange' }}>WHY JOIN GROUNDS & SPORTS?</h2>
                <p style={{ textAlign: 'justify' }}>
                    Unlock your full potential with Grounds & Sports! Here’s why becoming a part of our academy will be a game-changer for your athletic journey.
                </p>

                <h2 style={{ color: 'black' }}>COMPETE AT STATE LEVEL</h2>
                <p style={{ textAlign: 'justify' }}>
                    Our school sports teams proudly compete in Karnataka state leagues, giving you the opportunity to showcase your skills at a high level and gain valuable experience.
                </p>

                <h2 style={{ color: 'black' }}>PATHWAY TO ELITE PERFORMANCE</h2>
                <p style={{ textAlign: 'justify' }}>
                    We are dedicated to developing school, district, and state-level players. Our focused training programs ensure you receive the guidance and support needed to excel and achieve recognition.
                </p>

                <h2 style={{ color: 'black' }}>BOARD TOURNAMENT EXPOSURE</h2>
                <p style={{ textAlign: 'justify' }}>
                    Participate in a variety of tournaments, including internal, external, and private school competitions. This extensive exposure helps you refine your skills and compete against diverse talent.
                </p>

                <h2 style={{ color: 'black' }}>SELECTION OPPORTUNITY IN TOP SPORTS</h2>
                <p style={{ textAlign: 'justify' }}>
                    Whether it's basketball, badminton, cricket, or football, our rigorous selection process identifies and nurtures the most dedicated and talented players.
                </p>

                <h2 style={{ color: 'black' }}>REPRESENT AT MULTIPLE LEVELS</h2>
                <p style={{ textAlign: 'justify' }}>
                    Selected players have the chance to represent their school, clubs, and the Karnataka State Cricket Association (KSCA), opening doors to prestigious competitions and wider recognition.
                </p>

                <h2 style={{ color: 'black' }}>PROVEN SUCCESS FORMULA</h2>
                <p style={{ textAlign: 'justify' }}>
                    Our key to success lies in a blend of meticulous selections, expert coaching, rigorous training, and consistent practice. This holistic approach ensures you are well-prepared to tackle any challenge.
                </p>

                <h2 style={{ color: 'black' }}>PREMIER FACILITIES</h2>
                <p style={{ textAlign: 'justify' }}>
                    Benefit from divine providence with our state-of-the-art facilities. Our top-notch infrastructure provides the perfect environment for your training and development.
                </p>
            </div>
        </div>

<br/>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
  <img
    src={logo}
    alt="facilities"
    style={{ width: '300px', height: '150px', objectFit: 'cover' }}
  />
  <h1 style={{ margin: 0 }}>FOUNDERS AND TEAM</h1>
</div>
<br/>

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
  <div style={{ 
    backgroundColor: '#f5f5f5', // Light gray background color
    padding: '20px', 
    borderRadius: '8px', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
    textAlign: 'center',
    width: '100%', // Adjust width as needed
    maxWidth: '1200px', // Max width of the card
    margin: '0 auto' // Center the card horizontally
  }}>
    {/* First Row of Images and Info */}
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <img
          src={man}
          alt="man"
          style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
        />
        <h3 style={{ margin: '10px 0' }}>NAVIN KAIPU</h3>
        <p style={{ margin: '5px 0' }}>Founder & Operations Head</p>
        <p style={{ margin: '5px 0' }}>GROUNDS AND SPORTS</p>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <img
          src={man1}
          alt="man"
          style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
        />
        <h3 style={{ margin: '10px 0' }}>MATHEW SINCLAIR</h3>
        <p style={{ margin: '5px 0' }}>Founder, Head Coach, Chairperson of</p>
        <p style={{ margin: '5px 0' }}>Coaching Advisory Committee</p>
        <p style={{ margin: '5px 0' }}>New Zealand Ex-International</p>
        <p style={{ margin: '5px 0' }}>Cricketer</p>
        <p style={{ margin: '5px 0' }}>(Test, ODI & T20)</p>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <img
          src={man2}
          alt="man"
          style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
        />
        <h3 style={{ margin: '10px 0' }}>LLORNE HOWELL</h3>
        <p style={{ margin: '5px 0' }}>Founder, Senior Coach, Technical</p>
        <p style={{ margin: '5px 0' }}>Advisor, Chairperson of Coaching</p>
        <p style={{ margin: '5px 0' }}>Advisory Committee, New Zealand</p>
        <p style={{ margin: '5px 0' }}>Ex-International Cricketer</p>
        <p style={{ margin: '5px 0' }}>(ODI)</p>
      </div>
    </div>

    {/* Second Row of Images and Info */}
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <img
          src={man3}
          alt="man"
          style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
        />
        <h3 style={{ margin: '10px 0' }}>SHANKER V</h3>
        <p style={{ margin: '5px 0' }}>Advisor, MGMT & Chairperson</p>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <img
          src={man5}
          alt="man"
          style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
        />
        <h3 style={{ margin: '10px 0' }}>SHIVA KUMAR</h3>
        <p style={{ margin: '5px 0' }}>Marketing and Sales Head</p>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <img
          src={man6}
          alt="man"
          style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
        />
        <h3 style={{ margin: '10px 0' }}>EDWARD D'SOUZA</h3>
        <p style={{ margin: '5px 0' }}>HR, Accounts, Legal Advisor,</p>
        <p style={{ margin: '5px 0' }}>Business Analyst & India Operations Head</p>
      </div>
    </div>

    {/* Centered Image and Info */}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <img
        src={man7}
        alt="man"
        style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
      />
      <h3 style={{ margin: '10px 0' }}>KAMALA KANNAN V</h3>
      <p style={{ margin: '5px 0' }}>Manager</p>
      <p style={{ margin: '5px 0' }}>Grounds and Events</p>
    </div>
  </div>
</div>
<br/>
<div style={{ position: 'relative' }}>
      <img
        src={imagee}
        alt="man"
        style={{ height: '450px', width: '1350px', display: 'block' }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '80px', // Adjust as needed
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'white', // Adjust text color if needed
          fontSize: '36px', // Adjust text size if needed
          padding: '10px',
          borderRadius: '5px',
          fontFamily:'Sans-serif',
          fontWeight: 'bold', // Make text bold

        }}
      >
        READY TO START YOUR JOURNEY ?
      </div>
    </div>
<br/>
<div>
    <Homepage1/>
</div>
<br/>
<div>
  <Footer/>
</div>




</div>


    );
};

export default Aboutus;
