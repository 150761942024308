import React, { useEffect } from 'react';
import Navbar from './Navbar';
import image12 from '../images/bas.png';
import image14 from '../images/bas1.png';
import image20 from '../images/bas2.png';
import image21 from '../images/bas3.png';
import image22 from '../images/bb.png';
import image23 from '../images/cc.png';

import Footer from './Footer';
import image13 from '../images/ball1.png';
import Homepage1 from './Homepage1';

const Basketball = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

    return (
        <div>
            <Navbar />
            <br/><br/><br/><br/><br/><br/><br/>

            {/* Container for image12 and image13 */}
            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                 <img
                    src={image12}
                    alt="ball"
                    style={{ height: '500px', width: '100%', marginBottom: '10px' }}
                /> 
                
               
                <div style={{
                    position: 'absolute',
                    top: '20px', // Adjust as needed
                    left: '20px', // Adjust as needed
                    color: 'white',
                    fontFamily: 'Sans-serif',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}>
                    <div style={{
                        backgroundColor: 'gray',
                        padding: '10px',
                        borderRadius: '5px',
                    }}>
                        BASKETBALL
                    </div><br/><br/><br/><br/><br/>
                    <div style={{ marginTop: '30px' }}>
                        <div style={{
                            color: 'white',
                            fontFamily: 'Sans-serif',
                            fontWeight: 'bold',
                            fontSize: '60px',
                        }}>
                            TRAIN LIKE
                        </div>
                        <div style={{
                            color: 'white',
                            fontFamily: 'Sans-serif',
                            fontWeight: 'bold',
                            fontSize: '60px',
                        }}>
                            A <span style={{ color: 'orange' }}>CHAMP</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Container for image14 and text */}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>


                 <img
                    src={image14}
                    alt="ball"
                    style={{ height: '450px', width: '700px', marginRight: '20px' }}
                /> 
                <div style={{ fontFamily: 'Sans-serif', fontSize: '24px', fontWeight: 'bold', color: 'black' }}>
    <div style={{ marginBottom: '20px' }}>
        FOUNDING OF THE BASKETBALL ACADEMY
    </div><br/>
    <p style={{ fontFamily: 'Sans-serif', fontSize: '18px', color: 'black', fontWeight: 'normal' }}>
    At Grounds And Sports, we are dedicated to empowering basketball players to reach their
     full potential. Our basketball program is designed to accommodate players of all ages and 
     skill levels, from those just starting out to those aiming for competitive excellence.
<br/><br/><br/>
Join Grounds And Sports to take your basketball game to new heights. 
Our expert coaches, exceptional facilities, and commitment to player growth 
create an ideal environment for aspiring basketball stars..
</p>

</div>
</div>

<br/><br/>

<div style={{
    backgroundColor: 'black',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    maxWidth: '1350px',
    maxHeight: '800px',
}}>
    <div style={{ marginBottom: '10px' }}>
        <span style={{ color: 'white',fontSize: '40px', fontWeight: 'bold' }}>NEVER LET THE </span>
        <span style={{ color: 'orange',fontSize: '40px', fontWeight: 'bold' }}>FEAR</span>
        <span style={{ color: 'white',fontSize: '40px' , fontWeight: 'bold'}}> OF LOSING</span>
    </div>
    <div>
        <span style={{ color: 'white' ,fontSize: '40px', fontWeight: 'bold'}}>FROM </span>
        <span style={{ color: 'orange',fontSize: '40px', fontWeight: 'bold' }}>WINNING</span>
        <span style={{ color: 'white',fontSize: '40px' , fontWeight: 'bold'}}> THE GAME</span>
    </div>
    <div>
        <a 
            href="#register"  // Replace with your actual registration URL
            style={{ 
                color: 'lightgray', 
                fontSize: '25px', 
                fontWeight: 'bold', 
                textDecoration: 'none', 
                display: 'inline-block',
                marginTop: '20px'
            }}
        >
            REGISTER NOW
        </a>
    </div>
</div>
<br/><br/>
<div>
    <h1>GALLERY</h1>
<div style={{ textAlign: 'left', padding: '0 130px' }}>
    <img
        src={image20}
        alt="man"
        style={{ height: '350px', width: '500px', marginRight: '20px' }}
    />&emsp;&emsp;&emsp;
    <img
        src={image21}
        alt="man"
        style={{ height: '350px', width: '500px' }}
    />


      <p style={{ marginTop: '20px', fontSize: '16px', lineHeight: '1.5' }}>
      "At Grounds And Sports, we take pride in our state-of-the-art facilities designed to 
      support athletes at every level. Our sports grounds are equipped to host high-level 
      competitions and training sessions across cricket, football, and more. Explore our
       gallery to see where excellence in sports meets world-class infrastructure.
        From practice fields to competitive arenas, our facilities are tailored to foster
         talent and drive success."
    </p>
</div>
</div>

<div style={{ position: 'relative', textAlign: 'left' }}>

    <img
        src={image22}
        alt="football"
        style={{ height: '350px', width: '1350px' }}
    /> 
    <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontSize: '36px',
        fontWeight: 'bold',
        padding: '10px'
    }}>
        READY TO START YOUR JOURNEY?
    </div>
</div>
<br/>

<div style={{ padding: '0 20px' }}>
  {/* Top Section: Sentence */}
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div 
        style={{ 
          width: '5px', 
          height: '100px', 
          backgroundColor: 'orange', 
          marginRight: '10px'
        }}>
      </div>
      <div 
        style={{ 
          color: 'black', 
          fontWeight: 'bold', 
          fontSize: '28px'
        }}>
        <div>
          <span>READY FOR YOUR </span>
          <span style={{ color: 'skyblue' }}>FIRST</span>
          <span> TRAINING?</span>
        </div>
        <div 
          style={{ 
            color: 'lightgray', 
            fontSize: '14px', 
            marginTop: '5px'
          }}>
          JOIN THE TEAM!
        </div>
      </div>
    </div>
  </div>

  {/* Bottom Section: Boxes and Image */}
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
    {/* Left Side: Four Boxes */}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '70%' }}>
      <div 
        style={{ 
          backgroundColor: 'lightgray', 
          borderRadius: '40px', 
          padding: '10px', 
          width: '100%', // Changed from '30%' to '100%' for full width
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
        }}>
        <p>NAME</p>
      </div>
      <div 
        style={{ 
          backgroundColor: 'lightgray', 
          borderRadius: '40px', 
          padding: '10px', 
          width: '100%', // Changed from '30%' to '100%' for full width
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
        }}>
        <p>SURNAME</p>
      </div>
      <div 
        style={{ 
          backgroundColor: 'lightgray', 
          borderRadius: '40px', 
          padding: '10px', 
          width: '100%', // Changed from '30%' to '100%' for full width
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
        }}>
        <p>DATEOFBIRTH</p>
      </div>
      <div 
        style={{ 
          backgroundColor: 'lightgray', 
          borderRadius: '40px', 
          padding: '10px', 
          width: '100%', // Changed from '30%' to '100%' for full width
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
        }}>
        <p>PHONENUMBER</p>
      </div>
    </div>

    {/* Right Side: Image */}
    <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img
        src={image23}
        alt="football"
        style={{ height: '250px', width: 'auto', maxWidth: '100%' }}
      />
    </div>
  </div>
</div>

<br/><br/>
<div>
    <Homepage1/>
</div>


<div>
  <Footer/>
</div>



</div>


    );
};

export default Basketball;
