import * as React from "react";
import image from '../images/home.png';

function Homepage1() {
  return (
    <div>
      {/* Parent div with light gray background color */}
      <div style={{ backgroundColor: '#D3D3D3', minHeight: '100vh', padding: '30px 0' }}>
        <br /><br /><br /><br />
        <div style={{ 
            fontSize: '60px',
            fontFamily: 'Sans-serif',
            fontWeight: 'bold',
            marginLeft: '40px',
            color: 'black',
          }}
        >
          SHOP THE BEST IN SPORTS GEAR AT{" "}
          <br />
          <span style={{ color: '#FFA500' }}>GROUNDS AND SPORTS!</span>
        </div>

        {/* Flex container with reversed order */}
        <div style={{ display: 'flex', alignItems: 'center', padding: '30px 130px', gap: '20px' }}>
          {/* Container for the paragraph */}
          <div className="para-style" style={{ textAlign: 'left', color: 'white', maxWidth: '600px', order: 1 }}>
            <p style={{ fontSize: '21px', fontFamily: 'Sans-serif', color: 'black', textAlign: 'left' }}>
              Discover top-quality sports equipment and apparel at Grounds
              AND Sports. From cricket bats and footballs to badminton
              rackets and stylish sportswear, we have everything you need to
              elevate your game. Visit our academy to explore a wide range
              of gear from leading brands. Our expert staff is ready to
              assist you in finding the perfect equipment for your sporting
              needs.
            </p>
<br/><br/>
            {/* Contact Us Box aligned below the paragraph */}
          
          </div>

          {/* Container for the image */}
          <div className="flex-none" style={{ order: 2 }}>
            <img
              className="slide-icon"
              src={image}
              alt="home"
              style={{ height: '350px', width: '400px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage1;
