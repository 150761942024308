import React from "react";
import logo from '../images/logo.png'; // Ensure this is the correct path to your logo image
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div style={{ 
      backgroundColor: 'white', 
      padding: '20px 40px', // Adjust padding for spacing
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'space-between', 
      boxSizing: 'border-box',
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        {/* Left side content */}
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'flex-start', 
          maxWidth: '20%'
        }}>
          {/* White card container */}
          <div 
  style={{ 
    backgroundColor: 'white', 
    padding: '10px', 
    borderRadius: '8px', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
    textAlign: 'center',
    marginBottom: '10px',
    display: 'flex',         // Use flexbox for layout control
    justifyContent: 'center' // Center content horizontally
  }}
>
  {/* Logo */}
  <img 
    src={logo} 
    alt="Logo" 
    style={{ 
      height: '120px', 
      width: '120px', 
      marginLeft: '40px', // Increased margin to move the logo further right
    }} 
  />
</div>



          {/* Text below the card */}
          <div style={{ textAlign: 'left' }}>
            <h1 style={{ fontSize: '18px', margin: '0' }}>Grounds And Sports Academy</h1>
            <h2 style={{ fontSize: '14px', color: '#555', margin: '5px 0' }}>Address</h2>
          </div>
        </div>

        {/* Centered navigation links */}
        <div style={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'flex-start',  // Align items at the start of the flex container
  width: '100%', 
  margin: '0 auto'
}}>
  {/* Left-side content */}
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'flex-start', 
    maxWidth: '20%', 
    marginRight: '20px' 
  }}>
    <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>Company</h2>
    <Link to='/aboutus' style={{ margin: '5px 0', fontSize: '20px', fontFamily:
'Sans-serif',textDecoration: 'none', color: '#000' }}>
  About us
</Link>
<Link to='/gallery' style={{ margin: '5px 0', fontSize: '20px', fontFamily:
'Sans-serif',textDecoration: 'none', color: '#000' }}>
  Gallery
</Link>
 </div>

  {/* Right-side content */}
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'flex-start', 
    maxWidth: '20%' 
  }}>
    <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>Social</h2>
    <a href="#whatsapp" style={{ margin: '5px 0', fontSize: '18px', textDecoration: 'none',fontFamily:
'Sans-serif', color: '#000' }}>Whatsapp</a>
    <a href="#twitter" style={{ margin: '5px 0', fontSize: '18px', textDecoration: 'none',fontFamily:
'Sans-serif', color: '#000' }}>Twitter X</a>
    <a href="#linkedin" style={{ margin: '5px 0', fontSize: '18px', textDecoration: 'none',fontFamily:
'Sans-serif', color: '#000' }}>LinkedIn</a>
    <a href="#facebook" style={{ margin: '5px 0', fontSize: '18px', textDecoration: 'none',fontFamily:
'Sans-serif', color: '#000' }}>Facebook</a>
    <a href="#instagram" style={{ margin: '5px 0', fontSize: '18px', textDecoration: 'none',fontFamily:
'Sans-serif', color: '#000' }}>Instagram</a>
  </div>
</div>

      </div> {/* This closes the main content container */}

      {/* Bottom section */}
      <div style={{ 
        backgroundColor: '#0B1220', 
        padding: '10px 20px', 
        marginTop: '20px', 
        textAlign: 'center',
        color: 'white'
      }}>
     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
  <span style={{ marginBottom: '10px', fontSize: '24px' }}>©2024 – Grounds And Sports All Rights Reserved.</span>
  <div style={{ display: 'flex', gap: '10px' }}>
    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-twitter" style={{ fontSize: '28px', color: '#1DA1F2' }}></i> {/* Twitter Blue */}
    </a>&emsp;&emsp;
    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-linkedin" style={{ fontSize: '28px', color: '#0077B5' }}></i> {/* LinkedIn Blue */}
    </a>&emsp;&emsp;
    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-facebook-f" style={{ fontSize: '28px', color: '#1877F2' }}></i> {/* Facebook Blue */}
    </a>
  </div>
</div>

      </div>
    </div>
  );
}

export default Footer;
