import React, { useEffect } from 'react';
import Navbar from './Navbar';
import image12 from '../images/crik.png';
import image13 from '../images/crick1.png';
import image14 from '../images/c1.png';
import image1 from '../images/c2.png';
import image10 from '../images/foundamental.png';
import image20 from '../images/bowling.png';
import image21 from '../images/batting.png';
import image2 from '../images/g1.png';
import image3 from '../images/g2.png';
import image22 from '../images/a1.png';
import Footer from './Footer';
import image23 from '../images/c11.png';
import Homepage1 from './Homepage1';

const Cricket = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

    return (
        <div>
            <Navbar />
            <br/><br/><br/><br/><br/><br/><br/>
            {/* Container for image12 and image13 */}
            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                 <img
                    src={image12}
                    alt="ball"
                    style={{ height: '500px', width: '1350px', marginBottom: '20px' }}
                /> 
                 <img
    src={image13}
    alt="ball"
    style={{
      height: '350px',
      width: '350px',
      position: 'absolute',
      top: '160px', // Adjust as needed
      right: '20px', // Adjust as needed to position the image from the right edge
      transform: 'translateX(0)', // Remove horizontal centering since we are positioning from the right
    }}
  />
                
                <div style={{
                    position: 'absolute',
                    top: '20px', // Adjust as needed
                    left: '20px', // Adjust as needed
                    color: 'white',
                    fontFamily: 'Sans-serif',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}>
                    <div style={{
                        backgroundColor: 'gray',
                        padding: '10px',
                        borderRadius: '5px',
                    }}>
                        CRICKET
                    </div>
                    
                    <br/><br/><br/><br/><br/>

                    <div style={{ position: 'relative', width: '100%', height: '250px' }}>
  {/* Image */}
  {/* <img
    src={image23}
    alt="football"
    style={{ height: '100%', width: 'auto', position: 'absolute', right: 0, top: 0, objectFit: 'cover' }}
  /> */}
  
  {/* Text Container */}
  <div style={{
  position: 'absolute',
  top: '40%', // Center vertically
  left: '900%', // Center horizontally
  transform: 'translate(-50%, -50%)', // Adjust for centering
  color: 'white',
  fontFamily: 'Sans-serif',
  fontWeight: 'bold',
  fontSize: '40px',
  textAlign: 'center' // Center text
}}>
  <div>TRAIN LIKE</div>
  <div>
    A <span style={{ color: 'orange' }}>CHAMP</span>
  </div>
</div>

</div>
 </div>
     </div>

            {/* Container for image14 and text */}
            <div style={{ position: 'relative', marginTop: '20px', marginLeft: '90px', width: '600px', height: '300px' }}>
  <img
    src={image1}
    alt="image1"
    style={{
      height: '200px',
      width: '300px',
      position: 'absolute',
      top: '0',
      left: '50px',
      borderRadius: '15px',
      zIndex: 1,
    }}
  />
  <img
    src={image14}
    alt="image14"
    style={{
      height: '150px',
      width: '200px',
      position: 'absolute',
      top: '150px',
      left: '0',
      borderRadius: '15px',
      zIndex: 2,
    }}
  />

  <div style={{ fontFamily: 'Sans-serif', fontSize: '24px', fontWeight: 'bold', color: 'black' }}>
  <div style={{ position: 'relative', marginBottom: '20px' }}>
  <div style={{ position: 'absolute', right: -260 }}>
    FOUNDING OF THE CRICKET ACADEMY
  </div>
</div>
&emsp;&emsp;&emsp;
    <br /><br/>
    <div style={{ marginLeft: '350px', marginRight: '20px' }}>
      <div
        style={{
          backgroundColor: '#f5f5f5', // Light gray background
          padding: '10px',
          borderRadius: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow
          textAlign: 'right',
          height: '250px',  // Set the height of the card
          width: '800px',   // Set the width of the card
        }}
      >
        <p style={{ fontFamily: 'Sans-serif', fontSize: '18px', color: 'black', fontWeight: 'normal' }}>
          At Grounds And Sports, we are passionate about developing cricket talent and fostering a 
          love for the game. Our cricket program is designed to cater to players of all skill levels,
          from enthusiastic beginners to aspiring professionals.
          <br /><br /><br />
          Join us at Grounds And Sports to elevate your cricketing 
          skills and pursue your dreams in the sport. Our dedicated coaching staff, 
          top-notch facilities, and commitment to excellence make us the ideal choice for cricket
          enthusiasts looking to make their mark.
        </p>
      </div>
    </div>
  </div>
</div>

<br/><br/><br/>

<div style={{ textAlign: 'center', margin: '20px' }}>
  <h1>TRAINING COURSES</h1>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px' }}>
    <div style={{ flex: '1', textAlign: 'center' }}>
      <img
        src={image10}
        alt="Foundamentals Courses"
        style={{ height: '300px', width: '350px', margin: '0 auto' }}
      />
      <p>Foundamentals Courses</p>
    </div>
    <div style={{ flex: '1', textAlign: 'center' }}>
      <img
        src={image20}
        alt="Bowling Courses"
        style={{ height: '300px', width: '350px', margin: '0 auto' }}
      />
      <p>Bowling Courses</p>
    </div>
    <div style={{ flex: '1', textAlign: 'center' }}>
      <img
        src={image21}
        alt="Batting Courses"
        style={{ height: '300px', width: '350px', margin: '0 auto' }}
      />
      <p>Batting Courses</p>
    </div>
  </div>
</div>


<br/><br/>

<h1 class="center-text">GALLERY</h1>
<div style={{ textAlign: 'left', padding: '0 130px' }}>
    <img
        src={image2}
        alt="man"
        style={{ height: '350px', width: '500px', marginRight: '20px' }}
    />&emsp;&emsp;&emsp;
    <img
        src={image3}
        alt="man"
        style={{ height: '350px', width: '500px' }}
    />
      <p style={{ marginTop: '20px', fontSize: '16px', lineHeight: '1.5' }}>
      "At Grounds And Sports, we take pride in our state-of-the-art facilities designed to 
      support athletes at every level. Our sports grounds are equipped to host high-level 
      competitions and training sessions across cricket, football, and more. Explore our gallery 
      to see where excellence in sports meets world-class infrastructure. From practice fields to 
      competitive arenas, our facilities are tailored to foster talent and drive success."
    </p>
</div>


<div style={{ position: 'relative', textAlign: 'center' }}>
    <img
        src={image22}
        alt="football"
        style={{ height: '350px', width: '1350px' }}
    />
    <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontSize: '36px',
        fontWeight: 'bold',
        padding: '10px'
    }}>
        READY TO START YOUR JOURNEY?
    </div>
</div>
<br/>

<div style={{ padding: '0 20px' }}>
  {/* Top Section: Sentence */}
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div 
        style={{ 
          width: '5px', 
          height: '100px', 
          backgroundColor: 'orange', 
          marginRight: '10px'
        }}>
      </div>
      <div 
        style={{ 
          color: 'black', 
          fontWeight: 'bold', 
          fontSize: '28px'
        }}>
        <div>
          <span>READY FOR YOUR </span>
          <span style={{ color: 'skyblue' }}>FIRST</span>
          <span> TRAINING?</span>
        </div>
        <div 
          style={{ 
            color: 'lightgray', 
            fontSize: '14px', 
            marginTop: '5px'
          }}>
          JOIN THE TEAM!
        </div>
      </div>
    </div>
  </div>

  {/* Bottom Section: Boxes and Image */}
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
    {/* Left Side: Four Boxes */}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '70%' }}>
      <div 
        style={{ 
          backgroundColor: 'lightgray', 
          borderRadius: '40px', 
          padding: '10px', 
          width: '100%', // Changed from '30%' to '100%' for full width
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
        }}>
        <p>NAME</p>
      </div>
      <div 
        style={{ 
          backgroundColor: 'lightgray', 
          borderRadius: '40px', 
          padding: '10px', 
          width: '100%', // Changed from '30%' to '100%' for full width
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
        }}>
        <p>SURNAME</p>
      </div>
      <div 
        style={{ 
          backgroundColor: 'lightgray', 
          borderRadius: '40px', 
          padding: '10px', 
          width: '100%', // Changed from '30%' to '100%' for full width
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
        }}>
        <p>DATEOFBIRTH</p>
      </div>
      <div 
        style={{ 
          backgroundColor: 'lightgray', 
          borderRadius: '40px', 
          padding: '10px', 
          width: '100%', // Changed from '30%' to '100%' for full width
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
        }}>
        <p>PHONENUMBER</p>
      </div>
    </div>

    {/* Right Side: Image */}
    <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img
        src={image23}
        alt="football"
        style={{ height: '350px', width: 'auto', maxWidth: '100%' }}
      />
    </div>
  </div>
</div>


<br/><br/>
<div>
    <Homepage1/>
</div>


<div>
  <Footer/>
</div>



</div>


    );
};

export default Cricket;
