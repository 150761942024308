import { HashRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Homepage from './components/Homepage';
import Football from './components/Football';
import Cricket from './components/Cricket';
import Basketball from './components/Basketball';
import Badminton from './components/Badminton';
import Skating from './components/Skating';
import About from './components/Gallery';
import Gallery from './components/Gallery';
import Aboutus from './components/Aboutus';


function App() {
  return (
    <div>
      <Router>
        <Routes>
        <Route>
          <Route path="/" element={<Homepage />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/football" element={<Football />} />
          <Route path="/cricket" element={<Cricket />} />
          <Route path="/basketball" element={<Basketball />} />
          <Route path="/badminton" element={<Badminton />} />
          <Route path="/skating" element={<Skating />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/aboutus" element={<Aboutus />} />


        </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
