import React, { useEffect } from 'react';
import Navbar from './Navbar';
import image12 from '../images/ball.png';
import image13 from '../images/ball1.png';
import image14 from '../images/ball2.png';
import image20 from '../images/man11.png';
import image21 from '../images/man12.png';
import image22 from '../images/football11.png';
import image23 from '../images/b1.png';
import Homepage1 from './Homepage1';
import Footer from './Footer';

const Football = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    return (
        <div>
            <Navbar />
            <br/><br/><br/><br/><br/><br/><br/>

            {/* Container for image12 and image13 */}
            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <img
                    src={image12}
                    alt="ball"
                    style={{ height: '500px', width: '100%', marginBottom: '10px' }}
                />
                <img
                    src={image13}
                    alt="ball"
                    style={{
                        height: '200px',
                        width: '200px',
                        position: 'absolute',
                        top: '280px', // Adjust as needed
                        left: '50%',
                        transform: 'translateX(-50%)', // Center image13 horizontally
                    }}
                />
                <div style={{
                    position: 'absolute',
                    top: '20px', // Adjust as needed
                    left: '20px', // Adjust as needed
                    color: 'white',
                    fontFamily: 'Sans-serif',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}>
                    <div style={{
                        backgroundColor: 'gray',
                        padding: '10px',
                        borderRadius: '5px',
                    }}>
                        FOOTBALL
                    </div><br/><br/><br/><br/><br/>
                    <div style={{ marginTop: '30px' }}>
                        <div style={{
                            color: 'white',
                            fontFamily: 'Sans-serif',
                            fontWeight: 'bold',
                            fontSize: '60px',
                        }}>
                            TRAIN LIKE
                        </div>
                        <div style={{
                            color: 'white',
                            fontFamily: 'Sans-serif',
                            fontWeight: 'bold',
                            fontSize: '60px',
                        }}>
                            A <span style={{ color: 'orange' }}>CHAMP</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Container for image14 and text */}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                <img
                    src={image14}
                    alt="ball"
                    style={{ height: '450px', width: '700px', marginRight: '20px' }}
                />
                <div style={{ fontFamily: 'Sans-serif', fontSize: '24px', fontWeight: 'bold', color: 'black' }}>
                    <div style={{ marginBottom: '20px' }}>
                        FOUNDING OF THE FOOTBALL ACADEMY
                    </div><br/>
                    <p style={{ fontFamily: 'Sans-serif', fontSize: '18px', color: 'black', fontWeight: 'normal' }}>
                        At Grounds And Sports, we are dedicated to nurturing football talent and igniting a passion for the game. 
                        Our football program is crafted to support players at every level, 
                        from beginners eager to learn the basics to advanced players aiming to refine their
                        skills and compete at higher levels.<br/><br/><br/>
                        Join us at Grounds And Sports to elevate your football skills, enjoy the thrill of the game,
                        and pursue your footballing ambitions. With our expert coaching, excellent facilities, and 
                        focus on player development, we are committed to helping you excel on the field.
                    </p>
                </div>
            </div>

            <div style={{
                backgroundColor: 'black',
                color: 'white',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center',
                maxWidth: '1350px',
                maxHeight: '800px',
            }}>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ color: 'white',fontSize: '40px', fontWeight: 'bold' }}>NEVER LET THE </span>
                    <span style={{ color: 'orange',fontSize: '40px', fontWeight: 'bold' }}>FEAR</span>
                    <span style={{ color: 'white',fontSize: '40px' , fontWeight: 'bold'}}> OF LOSING</span>
                </div>
                <div>
                    <span style={{ color: 'white' ,fontSize: '40px', fontWeight: 'bold'}}>FROM </span>
                    <span style={{ color: 'orange',fontSize: '40px', fontWeight: 'bold' }}>WINNING</span>
                    <span style={{ color: 'white',fontSize: '40px' , fontWeight: 'bold'}}> THE GAME</span>
                </div>
                <div>
                    <a 
                        href="#register"  // Replace with your actual registration URL
                        style={{ 
                            color: 'lightgray', 
                            fontSize: '25px', 
                            fontWeight: 'bold', 
                            textDecoration: 'none', 
                            display: 'inline-block',
                            marginTop: '20px'
                        }}
                    >
                        REGISTER NOW
                    </a>
                </div>
            </div>
            <br/><br/>
            <div style={{ textAlign: 'left', padding: '0 130px' }}>
                <img
                    src={image20}
                    alt="man"
                    style={{ height: '350px', width: '500px', marginRight: '20px' }}
                />&emsp;&emsp;&emsp;
                <img
                    src={image21}
                    alt="man"
                    style={{ height: '350px', width: '500px' }}
                />
                <p style={{ marginTop: '20px', fontSize: '16px', lineHeight: '1.5' }}>
                    "At Grounds And Sports, we take pride in our state-of-the-art facilities designed to
                    support athletes at every level. Our sports grounds are equipped to host high-level 
                    competitions and training sessions across cricket, football, and more. Explore our
                    gallery to see where excellence in sports meets world-class infrastructure. From practice
                    fields to competitive arenas, our facilities are tailored to foster talent and drive success".
                </p>
            </div>

            <div style={{ position: 'relative', textAlign: 'center' }}>
                <img
                    src={image22}
                    alt="football"
                    style={{ height: '350px', width: '1350px' }}
                />
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    fontSize: '36px',
                    fontWeight: 'bold',
                    padding: '10px'
                }}>
                    READY TO START YOUR JOURNEY?
                </div>
            </div>
            <br/>

            <div style={{ padding: '0 20px' }}>
                {/* Top Section: Sentence */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div 
                            style={{ 
                                width: '5px', 
                                height: '100px', 
                                backgroundColor: 'orange', 
                                marginRight: '10px'
                            }}>
                        </div>
                        <div 
                            style={{ 
                                color: 'black', 
                                fontWeight: 'bold', 
                                fontSize: '28px'
                            }}>
                            <div>
                                <span>READY FOR YOUR </span>
                                <span style={{ color: 'orange' }}>FIRST</span>
                                <span> TRAINING?</span>
                            </div>
                            <div 
                                style={{ 
                                    color: 'lightgray', 
                                    fontSize: '14px', 
                                    marginTop: '5px'
                                }}>
                                JOIN THE TEAM!
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bottom Section: Boxes and Image */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    {/* Left Side: Four Boxes */}
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '70%' }}>
                        <div 
                            style={{ 
                                backgroundColor: 'lightgray', 
                                borderRadius: '40px', 
                                padding: '10px', 
                                width: '100%', // Changed from '30%' to '100%' for full width
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' 
                            }}>
                            <div 
                                style={{ 
                                    color: 'black', 
                                    fontSize: '24px', 
                                    fontWeight: 'bold', 
                                    textAlign: 'center'
                                }}>
                                WORKING HARD
                            </div>
                        </div>
                        <div 
                            style={{ 
                                backgroundColor: 'lightgray', 
                                borderRadius: '40px', 
                                padding: '10px', 
                                width: '100%', 
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}>
                            <div 
                                style={{ 
                                    color: 'black', 
                                    fontSize: '24px', 
                                    fontWeight: 'bold', 
                                    textAlign: 'center'
                                }}>
                                INJURY PREVENTION
                            </div>
                        </div>
                        <div 
                            style={{ 
                                backgroundColor: 'lightgray', 
                                borderRadius: '40px', 
                                padding: '10px', 
                                width: '100%', 
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}>
                            <div 
                                style={{ 
                                    color: 'black', 
                                    fontSize: '24px', 
                                    fontWeight: 'bold', 
                                    textAlign: 'center'
                                }}>
                                STRATEGIC TRAINING
                            </div>
                        </div>
                        <div 
                            style={{ 
                                backgroundColor: 'lightgray', 
                                borderRadius: '40px', 
                                padding: '10px', 
                                width: '100%', 
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}>
                            <div 
                                style={{ 
                                    color: 'black', 
                                    fontSize: '24px', 
                                    fontWeight: 'bold', 
                                    textAlign: 'center'
                                }}>
                                PERFORMANCE REVIEW
                            </div>
                        </div>
                    </div>

                    {/* Right Side: Image */}
                    <img
                        src={image23}
                        alt="football"
                        style={{ height: '500px', width: '500px' }}
                    />
                </div>
            </div>

            <Homepage1 />
            <Footer />
        </div>
    );
};

export default Football;
