import React, { useEffect } from 'react';
import Navbar from './Navbar';
import image12 from '../images/bad.png';
import image14 from '../images/abc1.png';
import image15 from '../images/abc.png';
import image1 from '../images/xyz.png';
import image2 from '../images/xyz1.png';
import image3 from '../images/a1.png';
import image from '../images/d1.png';
import images from '../images/d2.png';

import image17 from '../images/facilities.png';


import image22 from '../images/bads.png';
import image13 from '../images/bb.png';
import image23 from '../images/badss.png';
import Homepage1 from './Homepage1';
import Footer from './Footer';

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
}, []);
    return (
        <div>
            <Navbar />
            <br/><br/><br/><br/><br/><br/><br/>

            {/* Container for image12 and image13 */}
            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
  <img
    src={image17}
    alt="facilities"
    style={{ width: '100%', height: '500px', objectFit: 'cover' }}
  />
  <div style={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    padding: '20px',
  }}>
    <div style={{ fontSize: '20px' }}>
      ELEVATE YOUR GAME AND ACHIEVE YOUR FULL POTENTIAL WITH
    </div>
    <div style={{ fontSize: '44px', marginTop: '10px' }}>
      GROUNDS AND SPORTS
    </div>
  </div>
</div>

<div>
  <h1 style={{ textAlign: 'center' }}>TRAINING GROUND</h1>
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
    <img
      src={image14}
      alt="ball"
      style={{ height: '350px', width: '650px', marginLeft: '20px' }} // Space from the left margin
    />
    <img
      src={image15}
      alt="ball"
      style={{ height: '350px', width: '650px', marginRight: '20px' }} // Space from the right margin
    />
  </div>
  <p style={{ marginTop: '20px', textAlign: 'center' }}>
    Train like a pro in our world-class facilities. We offer premium grounds equipped 
    with the latest technology and amenities, ensuring an optimal training environment for
    all our athletes.
  </p>
</div>

<br/>

<div>
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
    <img
      src={image1}
      alt="ball"
      style={{ height: '350px', width: '650px', marginLeft: '20px' }} // Space from the left margin
    />
    <img
      src={image2}
      alt="ball"
      style={{ height: '350px', width: '650px', marginRight: '20px' }} // Space from the right margin
    />
  </div>
  <p style={{ marginTop: '20px', textAlign: 'center' }}>
  Join us at Grounds And Sports and become part of our success stories. Whether you’re 
  looking to improve your skills or aiming for the big leagues, we’re here to help you 
  achieve your goals.
  </p>
</div>

<br/><br/>

<div style={{ position: 'relative', width: '100%', height: '500px' }}>
  <img
    src={image3}
    alt="man2"
    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
  />
  <div
    style={{
      position: 'absolute',
      top: '20px', /* Adjust this value to position the text block from the top */
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      fontSize: '45px',
      fontFamily: 'Sans-serif',
      fontWeight: 'bold',
      zIndex: 1,
      width: '100%',
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'center', color: 'orange' }}>
      <div>CRICKET</div>
      <div style={{ marginLeft: '10px', color: 'white' }}>DOES NOT BUILD CHARACTER</div>
    </div>
    <div style={{ color: 'orange' }}>WILL EXPOSE IT!</div><br/>
    <div style={{ color: 'white',fontSize: '30px',fontFamily: 'Sans-serif',textDecoration: 'underline', marginTop: '10px' }}>
      REGISTER NOW
    </div>
  </div>
</div>
<br/>

<h1 style={{ textAlign: 'center' }}>SUCCESSFUL EVENTS DONE BY GROUND AND SPORTS</h1>

<div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '350px' }}>
    <img
      src={image}
      alt="facilities"
      style={{ width: '350px', height: '500px', objectFit: 'cover' }}
    />
    <br/>
   <div style={{ textAlign: 'center', width: '100%',fontSize: '16px', fontFamily: 'Sans-serif',}}>
  <p style={{ margin: '5px 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block' }}>
  <strong>Organizing Matches:</strong> Hosted numerous local and regional matches, providing platforms for athletes to showcase their talent.
  </p>
</div>
<div style={{ textAlign: 'center', width: '100%',fontSize: '16px',fontFamily: 'Sans-serif', }}>
  <p style={{ margin: '5px 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block' }}>
  <strong>Providing Opportunities:</strong> Comprehensive training programs and workshops for athletes of all skill levels.
  </p>
</div>
<div style={{ textAlign: 'center', width: '100%',fontSize: '16px', fontFamily: 'Sans-serif',}}>
  <p style={{ margin: '5px 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block' }}>
  <strong>Athlete Development:</strong> Helped many athletes reach national and international competition levels.
  </p>
</div>


      

  </div>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '350px' }}>
    <img
      src={images}
      alt="facilities"
      style={{ width: '350px', height: '500px', objectFit: 'cover' }}
    />
   
  </div>
</div>


<div>
    <Homepage1/>
</div>

<div>
  <Footer/>
</div>




</div>


    );
};

export default Gallery;
