import React, { useEffect } from 'react';
import Navbar from "../components/Navbar";
import image from '../images/photo.png'; // Ensure the path is correct for your project
import image1 from '../images/home2.png'; // Ensure the path is correct for your project
import image3 from '../images/home3.png';
import image11 from '../images/football.png';
import image12 from '../images/basketball.png';
import image13 from '../images/cricket.png';
import image14 from '../images/badminton.png';
import image15 from '../images/skating.png';
import image16 from '../images/man.png';
import image17 from '../images/facilities.png';
import image18 from '../images/man2.png';
import image19 from '../images/about.png';
import Homepage1 from "./Homepage1";
import { Link } from 'react-router-dom';
import Footer from "./Footer";

function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
}, []);


  return (
    <div>
      <Navbar />
      <br /><br /><br /><br /><br />
      {/* Parent div with light gray background color */}
      <div style={{ backgroundColor: 'white', minHeight: '100vh', padding: '30px 0' }}>
  {/* Container for the heading text, additional text, and image */}
  <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', padding: '30px 130px' }}>
    
    {/* Left side: Container with background image for both text sections */}
    <div
      style={{ 
        maxWidth: '50%', 
        padding: '20px',
        backgroundImage: `url(${image1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'black',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add a shadow for better separation
      }}
    >
      {/* First text section */}
      <div style={{ 
          fontSize: '60px',
          fontFamily: 'Sans-serif',
          fontWeight: 'bold',
          marginBottom: '20px', // Space between sections
        }}
      >
        WE DON’T BUILD
        <br />
        HOPES,
        <br />
        WE BUILD YOUR
        <br />
        FUTURE
      </div>

      {/* Second text section */}
      <div
        style={{
          fontSize: '21px',
          fontFamily: 'Sans-serif',
          color: 'black',
          lineHeight: '1.5',
          textAlign: 'right',
        }}
      >
        Unlock Your Full Potential With Grounds And Sports! Here’s Why Becoming A
        Part Of Our Academy Will Be A Game-Changer For Your Athletic Journey.
      </div>
    </div>

    {/* Right side: Container for the first image (home1.png) */}
    <div style={{ flexShrink: 0, display: 'flex', alignItems: 'flex-end' }}> {/* Ensure the image aligns well */}
      <img
        src={image}
        alt="home"
        style={{ height: '350px', width: '400px' }}
      />
    </div>
  </div>
</div>

{/* Container for the additional image with title and transparent boxes */}
<div style={{ position: 'relative', marginTop: '30px' }}>
  <img
    src={image3}
    alt="home"
    style={{ height: '600px', width: '100%', objectFit: 'cover' }} // Adjust width as needed
  />
  <br /><br />
  <div style={{
    position: 'absolute',
    right: '20px', // Position the title on the right side
    top: '20px', // Position the title at the top of the image
    color: 'white',
    fontSize: '36px',
    fontFamily: 'Sans-serif',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Optional: add text shadow for better readability
    display: 'flex',
    alignItems: 'center',
  }}>
    <span style={{ color: 'white', marginRight: '10px' }}>Welcome to</span>
    <span style={{ color: 'skyblue' }}>Grounds and Sports</span>
  </div>

  {/* Transparent boxes */}
  <div style={{
    position: 'absolute',
    top: '40%', // Position the first box from the top of the image
    left: '50%', // Center horizontally
    width: '1000px',
    height: '100px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // White background with 50% opacity
    borderRadius: '10px',
    border: '2px solid rgba(0, 0, 0, 0.2)', // Optional: border with slight shadow
    transform: 'translate(-50%, -50%)', // Center the div horizontally
    display: 'flex',
    justifyContent: 'center', // Center text horizontally
    alignItems: 'center', // Center text vertically
    padding: '20px', // Add padding inside the box
    boxSizing: 'border-box' // Include padding and border in the element's total width and height
  }}>
    <p style={{
      textAlign: 'center', // Center text within the paragraph
      margin: 0, // Remove default margins
      fontSize: '18px', // Adjust font size as needed
      lineHeight: '1.5', // Improve readability
      color: 'black' // Set text color
    }}>
      Are you ready to elevate your game and reach new heights in your athletic journey?
      At Grounds And Sports, we are committed to providing top-notch training, state of the art facilities, and a supportive community that fosters growth and excellence.
    </p>
  </div>
  
  <div style={{
    position: 'absolute',
    top: '55%', // Position the box from the top of the image, closer to the first box
    left: '10%', // Align the box to the left side with some margin from the edge
    width: '500px',
    height: '100px', // Fixed height
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // White background with 50% opacity
    borderRadius: '10px',
    border: '2px solid rgba(0, 0, 0, 0.2)', // Optional: border with slight shadow
    padding: '10px', // Reduce padding to make room for content
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
    overflow: 'hidden', // Hide overflow if content exceeds box dimensions
    display: 'flex',
    flexDirection: 'column', // Stack title and paragraph vertically
    justifyContent: 'center', // Center the content vertically within the box
  }}>
    <div style={{
      textAlign: 'left', // Align text to the left
      fontFamily: 'Sans-serif', // Use Sans-serif font family
      fontWeight: 'bold', // Make the text bold
      fontSize: '20px', // Adjust font size as needed
      marginBottom: '5px', // Adjust space below the title to fit within the box
    }}>
      Our Mission:
    </div>
    <p style={{
      textAlign: 'left', // Align text to the left
      margin: 0, // Remove default margins
      fontSize: '14px', // Adjust font size as needed to fit within the box
      lineHeight: '1.5', // Improve readability
      color: 'black' // Set text color
    }}>
      To create a nurturing environment where athletes can excel, pushing
      the boundaries of their potential and fostering a lifelong love for
      sports.
    </p>
  </div>

  <div style={{
    position: 'absolute',
    top: '55%', // Position the box from the top of the image, closer to the first box
    right: '10%', // Align the box to the right side with some margin from the edge
    width: '500px',
    height: 'auto', // Adjust height to fit content automatically
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // White background with 50% opacity
    borderRadius: '10px',
    border: '2px solid rgba(0, 0, 0, 0.2)', // Optional: border with slight shadow
    padding: '20px', // Add padding inside the box
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
    display: 'flex',
    flexDirection: 'column', // Stack the title and paragraph vertically
    justifyContent: 'center', // Center content vertically within the box
  }}>
    <div style={{
      textAlign: 'left', // Align text to the left
      fontFamily: 'Sans-serif', // Use Sans-serif font family
      fontWeight: 'bold', // Make the text bold
      fontSize: '20px', // Adjust font size as needed
      marginBottom: '5px', // Adjust space below the title
    }}>
      Our Vision:
    </div>
    <p style={{
      textAlign: 'left', // Align text to the left
      margin: 0, // Remove default margins
      fontSize: '14px', // Adjust font size as needed to fit within the box
      lineHeight: '1.5', // Improve readability
      color: 'black' // Set text color
    }}>
      To create a nurturing environment where athletes can excel, pushing
      the boundaries of their potential and fostering a lifelong love for
      sports.
    </p>
  </div>
</div>

<br/><br/><br/>
      <div style={{
  fontFamily: 'Sans-serif', 
  fontWeight: 'bold', 
  fontSize: '32px', 
  marginBottom: '20px' // Reduced space after heading
}}>
  PICK YOUR SPORTS
</div>
<br/><br/>
<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '0 20px' }}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
    <Link to='/football' style={{ textDecoration: 'none' }}>
        <div
            style={{
                backgroundColor: 'gray',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '8px',
                fontWeight: 'bold',
                width: '150px',
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '14px',
                marginBottom: '10px', // Adds space between heading and image
                cursor: 'pointer' // Changes the cursor to a pointer when hovering
            }}
        >
            FOOTBALL
        </div>
    </Link>
    <img
        src={image11}
        alt="football"
        style={{ height: '180px', width: '180px' }}
    />
</div>

  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <img
      src={image12}
      alt="basketball"
      style={{ height: '180px', width: '180px', marginBottom: '10px' }} // Adjusted marginBottom
    />
 <Link to='/basketball' style={{ textDecoration: 'none' }}>

<div
          style={{
              backgroundColor: 'gray',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '8px',
              fontWeight: 'bold',
              width: '150px',
                height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '14px',
              marginBottom: '10px', // Adds space between heading and image
              cursor: 'pointer' // Changes the cursor to a pointer when hovering
          }}
      >
      BASKETBALL
    </div>
    </Link>
  </div>

  <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Link to='/cricket' style={{ textDecoration: 'none' }}>

  <div
            style={{
                backgroundColor: 'gray',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '8px',
                fontWeight: 'bold',
                width: '150px',
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '14px',
                marginBottom: '10px', // Adds space between heading and image
                cursor: 'pointer' // Changes the cursor to a pointer when hovering
            }}
        >
      CRICKET
    </div>
    </Link>
    <img
      src={image13}
      alt="cricket"
      style={{ height: '180px', width: '180px', marginTop: '10px' }} // Adjusted marginTop
    />
  </div>

  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <img
      src={image14}
      alt="basketball"
      style={{ height: '180px', width: '180px', marginBottom: '10px' }} // Adjusted marginBottom
    />
 <Link to='/badminton' style={{ textDecoration: 'none' }}>

<div
          style={{
              backgroundColor: 'gray',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '8px',
              fontWeight: 'bold',
              width: '150px',
                height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '14px',
              marginBottom: '10px', // Adds space between heading and image
              cursor: 'pointer' // Changes the cursor to a pointer when hovering
          }}
      >
      BADMINTON
    </div>
    </Link>
  </div>

  <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Link to='/skating' style={{ textDecoration: 'none' }}>

<div
          style={{
            backgroundColor: 'gray',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '8px',
            fontWeight: 'bold',
            width: '150px',
              height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '14px',
            marginBottom: '10px', // Adds space between heading and image
            cursor: 'pointer' // Changes the cursor to a pointer when hovering
        }}
    
      >   SKATING
    </div>
    </Link>
    <img
      src={image15}
      alt="skating"
      style={{ height: '180px', width: '180px', marginTop: '10px' }} // Adjusted marginTop
    />
  </div>
</div>

<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh', marginTop: '20px' }}>
  <div style={{ 
    backgroundColor: '#f0f0f0', // Light gray background color
    padding: '20px', // Padding inside the card
    borderRadius: '8px', // Rounded corners for the card
    display: 'flex', 
    alignItems: 'center', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional shadow for better appearance
    height: '250px', // Increased height of the card
    width: '1350px' // Increased width of the card
  }}>
    <img
      src={image16}
      alt="man"
      style={{ height: '250px', width: '250px', marginRight: '20px' }}
    />
    <div>
      <p style={{ fontFamily: 'Sans-serif', fontSize: '38px', fontWeight: 'bold', margin: 0, whiteSpace: 'nowrap' }}>
        <span style={{ color: 'orange' }}>Cricket is a mind game</span>,&nbsp;
        <span style={{ color: 'black' }}>more than a physical game</span>
      </p><br/><br/>
      <p style={{ fontFamily: 'Sans-serif', fontSize: '18px', fontWeight: 'bold', margin: '10px 0 0', textAlign: 'right', color: 'black' }}>
        -MATHEW SINCLAIR
      </p>
      <p style={{ fontFamily: 'Sans-serif', fontSize: '14px', margin: '0', textAlign: 'right', color: '#555' }}>
        Former International New Zealand Cricketer
      </p>
    </div>
  </div>
</div>




<div style={{ position: 'relative', textAlign: 'center' }}>
  {/* Title above the boxes */}
  <div style={{
    position: 'absolute',
    top: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'Sans-serif',
    fontSize: '36px',
    fontWeight: 'bold',
    color: 'white'
  }}>
    OUR FACILITIES
  </div>

  {/* Background image */}
  <img
    src={image17}
    alt="facilities"
    style={{ width: '100%', height: '500px', objectFit: 'cover' }}
  />

  {/* Transparent boxes */}
  <div style={{ position: 'absolute', top: '100px', left: '50%', transform: 'translateX(-50%)', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%' }}>
    {[
      "Coaches – Providing coaches for all sports- Schools, Academies and Corporate teams",
      "Grounds – rental base, lease and long term for Basketball, Cricket and Football",
      "Development Indoor and outdoor sports facilities - astro turf, Badminton, basketball courts turf and multisport",
      "Sports tours and travel Plans – National  and International",
      "Sports Tournaments -Interschool, Academies and corporates"
    ].map((text, index) => (
      <div
        key={index}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // White with transparency
          padding: '10px',
          marginBottom: '10px',
          width: '80%',
          maxWidth: '500px',
          borderRadius: '8px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
          textAlign: 'center'
        }}
      >
        {text}
      </div>
    ))}
  </div>
</div>

<br/>

<div style={{ position: 'relative', width: '100%', height: '500px' }}>
  <img
    src={image18}
    alt="man2"
    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
  />
  <div
    style={{
      position: 'absolute',
      top: '20px', /* Adjust this value to position the text block from the top */
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      fontSize: '45px',
      fontFamily: 'Sans-serif',
      fontWeight: 'bold',
      zIndex: 1,
      width: '100%',
    }}
  ><br/>
    <div style={{ display: 'flex', justifyContent: 'center', color: 'orange' }}>
      <div>CRICKET</div>
      <div style={{ marginLeft: '10px', color: 'white' }}>DOES NOT BUILD CHARACTER</div>
    </div>
    <div style={{ color: 'orange' }}>WILL EXPOSE IT!</div><br/>
   
  </div>
</div>

<br/>

<div style={{ position: 'relative', width: '100%', height: '100%' }}>
  <img
    src={image19}
    alt="about"
    style={{ width: '1350px', height: '450px', objectFit: 'cover' }}
  />
  <div style={{
    position: 'absolute',
    top: '70px',  // Position slightly below the top
    left: '30px', // Padding from the left edge
    color: 'white',
    fontSize: '60px',
    fontFamily: 'Sans-serif',
    textAlign: 'left',
    lineHeight: '1.2',  // Adjust line height for spacing between lines
  }}>
    ABOUT US   <br/><br/>
    <div style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '20px', color: 'white' }}>
      Play like a pro and get your SKILLS.
    </div>
    <div style={{ fontSize: '30px', fontWeight: 'bold', color: 'orange' }}>
      GROUNDS AND SPORTS
    </div>
    <div style={{ fontSize: '30px', fontWeight: 'bold', color: 'white' }}>
      is the place to achieve your dream.
    </div><br/>
    
  </div>
</div>



<div>
  <Homepage1/>
</div>
<br/>
<div>
  <Footer/>
</div>



      </div>


  );
}

export default Homepage;