import React from 'react';
import { Link } from 'react-router-dom';
import image from '../images/logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS

const Navbar = () => {
  return (
    <div>
      {/* Navbar */}
      <div
        style={{
          padding: '15px',
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          zIndex: 1000, // Ensures the navbar is above other content
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between', // Space out the logo and navbar items
          alignItems: 'center', // Center items vertically
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Adds shadow for better visibility
        }}
      >
        {/* Logo */}
        <div className="d-flex align-items-center">
  <img
    className="top-bar__logo"
    src={image}
    alt='logo'
    style={{
      height: '100px',
      width: '140px',
      marginLeft: '140px'  /* Adjust this value as needed */
    }}
  />
</div>



        {/* Navigation Items */}
        <ul
          style={{
            display: 'flex',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            backgroundColor: 'white',
            marginLeft: 'auto', // Aligns the nav items to the right of the logo
          }}
        >
          <li style={{ margin: '0px 75px' }}>
            <Link to="/aboutus" style={{ color: 'black', textDecoration: 'none', position: 'relative' }}>
              <span className='playfair-display'
                style={{
                  position: 'relative',
                  borderBottom: '4px solid transparent',
                  paddingBottom: '12px',
                  marginRight: '5px',
                  transition: 'border-color 0.3s ease',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  fontFamily: 'Sans-serif',
                }}
                onMouseOver={(e) => e.target.style.borderColor = 'black'}
                onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
              >
                About Us
              </span>
            </Link>
          </li>
          &emsp;
          
          <li style={{ margin: '0px 75px' }}>
          <Link to="/gallery" style={{ color: 'black', textDecoration: 'none', position: 'relative' }}>

            <a
              href='#'
              style={{
                color: 'black',
                textDecoration: 'none',
                position: 'relative',
                fontWeight: 'bold'
              }}
            >
              <span className='playfair-display'
                style={{
                  position: 'relative',
                  borderBottom: '4px solid transparent',
                  paddingBottom: '12px',
                  marginRight: '5px',
                  transition: 'border-color 0.3s ease',
                  fontSize: '18px',
                  fontFamily: 'Sans-serif',
                }}
                onMouseOver={(e) => e.target.style.borderColor = 'black'}
                onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
              >
                Gallery
              </span>
              
            </a>
            </Link>
          </li>
          &emsp;
         
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
